import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { actionDeleteFaxDevice } from "../store/actions/faxDeviceAction";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import LoaderGif from "../assets/loader/Rolling.gif";

function DeviceTableRow({
  faxDeviceID,
  index,
  device_ID,
  deviceName,
  activationDate,
  billingDate,
  isActive,
}) {
  const dispatch = useDispatch();
  const deleteFaxDeviceLoader = useSelector(
    (state) => state.faxDevice.deleteFaxDeviceLoader
  );

  console.log({ deleteFaxDeviceLoader });

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will permanently delete the fax device, and all faxes associated with this device will be removed.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(actionDeleteFaxDevice(faxDeviceID));
      }
    });
  };
  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td>{device_ID}</td>
      <td>{deviceName}</td>
      <td>{moment(activationDate).format("DD MMM - hh:mm A")}</td>
      <td>{moment(billingDate).format("DD MMM - hh:mm A")}</td>
      <td className="text-center">
        <button
          type="button"
          className={`btn btn-soft-${
            isActive ? "success" : "danger"
          } waves-effect waves-light`}
        >
          {isActive ? "Active" : "Deactive"}
        </button>
      </td>
      <td className="text-center">
        <Link
          to={`/configuration/${faxDeviceID}`}
          className="btn btn-outline-secondary btn-sm"
          title="Edit"
          style={{ marginRight: 5 }}
        >
          <i className="fas fa-gear" title="Configure" />
        </Link>
        <button
          className="btn btn-outline-secondary btn-sm delete"
          title="Delete"
          onClick={handleDelete}
        >
          {deleteFaxDeviceLoader == faxDeviceID ? (
            <img src={LoaderGif} alt="Loading" />
          ) : (
            <i className="fas fa-trash-alt" title="Delete"></i>
          )}
        </button>
      </td>
    </tr>
  );
}

export default DeviceTableRow;
