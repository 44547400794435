import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import DefaultView from "../components/DefaultView";
import AddFundForm from "../components/AddFundForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const AddFund = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Billing</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              {/* start card */}
              <div className="card mt-4">
                <div className="card-header">
                  <h4 className="card-title mb-0">Add Fund</h4>
                </div>
                <div className="card-body">
                  <Elements stripe={stripePromise}>
                    <AddFundForm />
                  </Elements>
                </div>
                {/* end card */}
              </div>
              {/* end col */}
            </div>
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>
    </div>
  );
};

export default AddFund;
