import React from "react";
import moment from "moment"; // Importing moment for date formatting

function DeviceCallReportTableRow({
  index,
  callid,
  device_ID,
  from,
  to,
  datetime,
  pages,
  duration,
  direction,
  type,
}) {
  // Format datetime to a readable format using moment
  const formattedDatetime = datetime
    ? moment(datetime).format("YYYY-MM-DD hh:mm A")
    : "N/A";

  // Handle empty or invalid values gracefully
  const displayValue = (value) => (value ? value : "N/A");

  return (
    <tr className={index % 2 === 0 ? "even" : "odd"}>
      <td>{displayValue(callid)}</td>
      <td>{displayValue(device_ID)}</td>
      <td>{displayValue(from)}</td>
      <td>{displayValue(to)}</td>
      <td>{formattedDatetime}</td>
      <td className="text-center">{displayValue(pages)}</td>
      <td className="text-center">{displayValue(duration)}</td>
      <td className="text-center">{displayValue(direction)}</td>
      <td className="text-center">{displayValue(type)}</td>
    </tr>
  );
}

export default DeviceCallReportTableRow;
