import React from "react";
import Workbook from "react-excel-workbook";
import moment from "moment";

const DeviceCallReportExportToExcel = ({ data }) => (
  <Workbook
    filename="DeviceCallReport.xlsx"
    element={
      <button
        className="btn btn-success buttons-excel buttons-html5 mx-1"
        tabIndex={0}
        aria-controls="datatable-buttons"
        type="button"
      >
        <span>Export to Excel</span>
      </button>
    }
  >
    <Workbook.Sheet name="Call Report Data" data={data}>
      <Workbook.Column label="Call ID" value={(row) => row.call_id} />
      <Workbook.Column label="Device ID" value={(row) => row.device_ID} />
      <Workbook.Column label="From" value={(row) => row.from} />
      <Workbook.Column label="To" value={(row) => row.to} />
      <Workbook.Column
        label="Datetime"
        value={(row) => moment(row.datetime).format("YYYY/MM/DD HH:mm:ss")}
      />
      <Workbook.Column label="Pages" value={(row) => row.pages} />
      <Workbook.Column label="Duration" value={(row) => row.duration} />
      <Workbook.Column label="Direction" value={(row) => row.direction} />
      <Workbook.Column label="Type" value={(row) => row.type} />
    </Workbook.Sheet>
  </Workbook>
);

export default DeviceCallReportExportToExcel;
