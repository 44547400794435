import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import moment from "moment"; // Import moment.js
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import DefaultView from "../components/DefaultView";
import DeviceCallReportExportToExcel from "../components/DeviceCallReportExportToExcel";
import DeviceCallReportExportToPDF from "../components/DeviceCallReportExportToPDF";
import DeviceCallReportTableRow from "../components/DeviceCallReportTableRow";
import { actionGetDeviceCallReport } from "../store/actions/faxDeviceAction";

function DeviceCallReport({
  faxDeviceCallReportLoader,
  faxDeviceCallReport,
  actionGetDeviceCallReport,
}) {
  const datepickerRef = useRef(null);

  // State for filters
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [callType, setCallType] = useState("");
  const [deviceId, setDeviceId] = useState("");

  // Initialize Flatpickr for single date selection
  useEffect(() => {
    flatpickr(datepickerRef.current, {
      enableTime: false,
      dateFormat: "Y-m-d",
      onChange: (selectedDates) => {
        if (selectedDates.length) {
          console.log(selectedDates[0]);
          setSelectedDate(selectedDates[0]);
        }
      },
    });
  }, []);

  // Filter records dynamically
  const applyFilters = () => {
    let filtered = faxDeviceCallReport;

    // Apply selected date filter using moment.js
    if (selectedDate) {
      filtered = filtered.filter((record) => {
        const recordDate = moment(record.created_at).format("YYYY-MM-DD");
        const selectedMomentDate = moment(selectedDate).format("YYYY-MM-DD");
        return recordDate === selectedMomentDate;
      });
    }

    // Apply search text filter
    if (searchText) {
      filtered = filtered.filter((record) =>
        record.from.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Apply call type filter
    if (callType) {
      filtered = filtered.filter((record) => record.type === callType);
    }

    // Apply device ID filter
    if (deviceId) {
      filtered = filtered.filter((record) => record.device_ID == deviceId);
    }

    setFilteredRecords(filtered);
  };

  // Clear all filters
  const clearFilters = () => {
    setSelectedDate("");
    setSearchText("");
    setCallType("");
    setDeviceId("");
    setFilteredRecords(faxDeviceCallReport); // Reset to original data
  };

  // Fetch data on component mount
  useEffect(() => {
    actionGetDeviceCallReport();
  }, [actionGetDeviceCallReport]);

  // Update filteredRecords to show all records if no filters are applied
  useEffect(() => {
    if (!selectedDate && !searchText && !callType && !deviceId) {
      setFilteredRecords(faxDeviceCallReport); // Show all records if no filter is applied
    }
  }, [faxDeviceCallReport, selectedDate, searchText, callType, deviceId]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 main_heading">Device Call Report</h4>
                <div className="page-title-right">
                  <DefaultView />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="invoiceCard mb-3 mt-4">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label">Date:</label>
                      <input
                        type="text"
                        className="form-control flatpickr-input"
                        id="datepicker"
                        readOnly="readonly"
                        ref={datepickerRef}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label">Type:</label>
                      <select
                        className="form-select"
                        value={callType}
                        onChange={(e) => setCallType(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="IN">IN</option>
                        <option value="OUT">OUT</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label">Device ID:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Device ID"
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-end p-0 m-0">
                    <div className="col-md-2">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={clearFilters}
                      >
                        Clear Filters
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary w-100"
                        onClick={applyFilters}
                      >
                        Apply Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card invoiceCard">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div>
                    <h4 className="card-title">Device Call Report</h4>
                    <p className="card-title-desc">
                      Filter and view device call reports dynamically.
                    </p>
                  </div>

                  <div className="d-flex">
                    <DeviceCallReportExportToExcel data={filteredRecords} />
                    <DeviceCallReportExportToPDF data={filteredRecords} />
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {faxDeviceCallReportLoader ? (
                      <div className="text-center">Loading...</div>
                    ) : filteredRecords.length === 0 ? (
                      <div className="text-center">Data Not Found</div>
                    ) : (
                      <table className="invoiceTable table table-bordered dt-responsive nowrap w-100">
                        <thead>
                          <tr>
                            <th>CallID</th>
                            <th>Device ID</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Datetime</th>
                            <th>Pages</th>
                            <th>Duration</th>
                            <th>Direction</th>
                            <th>Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredRecords.map((item, index) => (
                            <DeviceCallReportTableRow
                              key={index}
                              index={index}
                              callid={item.call_id}
                              device_ID={item.device_ID}
                              from={item.from}
                              to={item.to}
                              datetime={item.created_at}
                              pages={item.pages}
                              duration={item.duration}
                              direction={item.direction}
                              type={item.type}
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  faxDeviceCallReportLoader: state.faxDevice.faxDeviceCallReportLoader,
  faxDeviceCallReport: state.faxDevice.faxDeviceCallReport,
});

export default connect(mapStateToProps, { actionGetDeviceCallReport })(
  DeviceCallReport
);
