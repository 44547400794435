import React, { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DeviceCallReportExportToPDF = ({ data }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "A4"); // Portrait, points, A4 size

    const tableColumns = [
      "Call ID",
      "Device ID",
      "From",
      "To",
      "Datetime",
      "Pages",
      "Duration",
      "Direction",
      "Type",
    ];

    const tableRows = data.map((row) => [
      row.call_id,
      row.device_ID,
      row.from,
      row.to,
      moment(row.datetime).format("YYYY/MM/DD HH:mm:ss"),
      row.pages,
      row.duration,
      row.direction,
      row.type,
    ]);

    doc.text("Device Call Report", 40, 40); // Title
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 60, // Starting y-position of the table
    });

    doc.save("DeviceCallReport.pdf");
  };

  return (
    <div>
      {/* Hidden table for printing */}
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <table id="device-call-report-table">
            <thead>
              <tr>
                <th>Call ID</th>
                <th>Device ID</th>
                <th>From</th>
                <th>To</th>
                <th>Datetime</th>
                <th>Pages</th>
                <th>Duration</th>
                <th>Direction</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row.call_id}</td>
                  <td>{row.device_ID}</td>
                  <td>{row.from}</td>
                  <td>{row.to}</td>
                  <td>{moment(row.datetime).format("YYYY/MM/DD HH:mm:ss")}</td>
                  <td>{row.pages}</td>
                  <td>{row.duration}</td>
                  <td>{row.direction}</td>
                  <td>{row.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Buttons for PDF generation and printing */}
      <div className="inline">
        <button
          className="btn btn-primary buttons-pdf buttons-html5"
          onClick={generatePDF}
        >
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default DeviceCallReportExportToPDF;
