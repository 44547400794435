import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { actionGetFaxHeader } from "../store/actions/settingAction";
import {
  actionAddFaxDevice,
  actionGetSingleFaxDevice,
  actionUpdateFaxDevice,
} from "../store/actions/faxDeviceAction";
import { actionGetVerifiedPhoneFaxNumbers } from "../store/actions/authAction";
import Swal from "sweetalert2";

function AddFaxDevice({
  actionGetFaxHeader,
  faxHeaderData,
  faxHeaderloder,
  actionGetVerifiedPhoneFaxNumbers,
  verifiedPhoneFaxNumbers,
  user,
  addFaxDeviceLoader,
  actionAddFaxDevice,
  actionGetSingleFaxDevice,
  faxDevice,
  actionUpdateFaxDevice,
}) {
  const [routingOption, setRoutingOption] = useState(""); // Manage routing options
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [numberOfChannels, setNumberOfChannels] = useState(2);
  const [callerID, setCallerID] = useState("");
  const [setHeaderCover, setSetHeaderCover] = useState("");
  const [errors, setErrors] = useState({}); // Track validation errors
  const [isEditMode, setIsEditMode] = useState(false); // Add/Edit Mode
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    actionGetFaxHeader();
  }, [actionGetFaxHeader]);

  useEffect(() => {
    user && actionGetVerifiedPhoneFaxNumbers(user.user_ID);
  }, [user]);

  useEffect(() => {
    // Fetch the single fax device for editing
    if (id) {
      setIsEditMode(true);
      actionGetSingleFaxDevice(id);
    }
  }, [id, actionGetSingleFaxDevice]);

  useEffect(() => {
    // Populate the form fields when in edit mode
    if (faxDevice && isEditMode) {
      setDeviceName(faxDevice.device_name || "");
      setNumberOfChannels(faxDevice.number_of_channels || 2);
      setRoutingOption(faxDevice.routing_options || "");
      setSelectedEmail(faxDevice.selected_email || "");
      setSelectedPhoneNumber(faxDevice.selected_phone_number || "");
      setCallerID(faxDevice.set_caller_id || "");
      setSetHeaderCover(faxDevice.set_header_cover || "");
    }
  }, [faxDevice, isEditMode]);

  const validate = () => {
    const newErrors = {};

    if (!deviceName) {
      newErrors.deviceName = "Device Name is required.";
    }

    if (!numberOfChannels || isNaN(numberOfChannels)) {
      newErrors.numberOfChannels = "Number of Channels must be a valid number.";
    } else if (
      numberOfChannels < 2 ||
      numberOfChannels > 32 ||
      numberOfChannels % 2 !== 0
    ) {
      newErrors.numberOfChannels =
        "Number of Channels must be an even number between 2 and 32.";
    }

    if (routingOption === "EMAIL" && !/\S+@\S+\.\S+/.test(selectedEmail)) {
      newErrors.selectedEmail = "A valid email is required.";
    }

    if (routingOption === "FAX_NUMBER" && !selectedPhoneNumber) {
      newErrors.selectedPhoneNumber = "Phone Number is required.";
    }

    if (!callerID) {
      newErrors.callerID = "Caller ID is required.";
    }

    if (!setHeaderCover) {
      newErrors.setHeaderCover = "Header is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit called");
    if (validate()) {
      const request = {
        device_name: deviceName,
        number_of_channels: numberOfChannels,
        routing_options: routingOption,
        selected_email: selectedEmail,
        selected_phone_number: selectedPhoneNumber,
        set_caller_id: callerID,
        set_header_cover: setHeaderCover,
      };

      if (isEditMode) {
        console.log("inside edit");
        // Call update action with device ID
        actionUpdateFaxDevice(id, request, navigate);
      } else {
        console.log("inside add");
        Swal.fire({
          title: "Confirm Price",
          text: `Adding this device will cost you $${process.env.REACT_APP_FAX_DEVICE_COST}. Are you sure you want to proceed?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Add Device",
        }).then((result) => {
          if (result.isConfirmed) {
            // Call add action
            actionAddFaxDevice(request, navigate);
          }
        });
      }
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-top justify-content-between">
                  <h4 className="mb-sm-0 main_heading">
                    {isEditMode ? "Update Device" : "Add Device"}
                  </h4>
                </div>
              </div>
              <div className="col-12">
                <div className="alert alert-info" role="alert">
                  Adding a fax device will cost $
                  {process.env.REACT_APP_FAX_DEVICE_COST} per month.
                </div>
              </div>
            </div>

            {/* Display Info Card */}
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Display Info</h4>
                <p className="card-title-desc">Provide Device Information</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="number-of-channels" className="form-label">
                      Number of Channels
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Number of Channels"
                      value={numberOfChannels}
                      onChange={(e) => setNumberOfChannels(e.target.value)}
                    />
                    {errors.numberOfChannels && (
                      <small className="text-danger">
                        {errors.numberOfChannels}
                      </small>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="device-name" className="form-label">
                      Device Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Device Name"
                      value={deviceName}
                      onChange={(e) => setDeviceName(e.target.value)}
                    />
                    {errors.deviceName && (
                      <small className="text-danger">{errors.deviceName}</small>
                    )}
                  </div>
                </div>

                {isEditMode && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-6">
                            <label
                              for="example-text-input"
                              className="form-label"
                            >
                              Number of Incoming Calls
                            </label>
                          </div>
                          <div className="col-6">
                            <p className="d-flex justify-content-end mb-0">
                              100
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-6">
                          <label
                            for="example-text-input"
                            className="form-label"
                          >
                            Number of Outgoing Calls
                          </label>
                        </div>
                        <div className="col-6">
                          <p className="d-flex justify-content-end mb-0">100</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isEditMode && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-6">
                          <label
                            for="example-text-input"
                            className="form-label"
                          >
                            Show SIP URI
                          </label>
                        </div>
                        <div className="col-6">
                          <p className="d-flex justify-content-end mb-0">
                            sip:bob.smith@nokia.com
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-6">
                          <label
                            for="example-text-input"
                            className="form-label"
                          >
                            Status
                          </label>
                        </div>
                        <div className="col-6">
                          <p className="d-flex justify-content-end mb-0">
                            {faxDevice?.status}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Incoming Form Card */}
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Incoming Form</h4>
                <p className="card-title-desc">Routing Options</p>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <label>Routing Options:</label>
                  <select
                    className="form-select"
                    value={routingOption}
                    onChange={(e) => setRoutingOption(e.target.value)}
                  >
                    <option value="">Select Route</option>
                    <option value="EMAIL">Email</option>
                    <option value="FAX_NUMBER">Fax Number</option>
                  </select>
                </div>
                {routingOption === "EMAIL" && (
                  <div className="mb-3">
                    <label>Enter Email:</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Email"
                      value={selectedEmail}
                      onChange={(e) => setSelectedEmail(e.target.value)}
                    />
                    {errors.selectedEmail && (
                      <small className="text-danger">
                        {errors.selectedEmail}
                      </small>
                    )}
                  </div>
                )}
                {routingOption === "FAX_NUMBER" && (
                  <div className="mb-3">
                    <label>Enter Fax Number:</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Phone Number"
                      value={selectedPhoneNumber}
                      onChange={(e) => setSelectedPhoneNumber(e.target.value)}
                    />
                    {errors.selectedPhoneNumber && (
                      <small className="text-danger">
                        {errors.selectedPhoneNumber}
                      </small>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Outgoing Form Card */}
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Outgoing Form</h4>
                <p className="card-title-desc">Set Caller Info</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="set-caller-id" className="form-label">
                      CallerID
                    </label>
                    <select
                      className="form-select"
                      value={callerID}
                      onChange={(e) => setCallerID(e.target.value)}
                    >
                      <option value="">Select Caller ID</option>
                      {verifiedPhoneFaxNumbers &&
                        verifiedPhoneFaxNumbers.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.number}
                          </option>
                        ))}
                    </select>
                    {errors.callerID && (
                      <small className="text-danger">{errors.callerID}</small>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="set-header-cover" className="form-label">
                      Header
                    </label>
                    <select
                      className="form-select"
                      value={setHeaderCover}
                      onChange={(e) => setSetHeaderCover(e.target.value)}
                    >
                      <option value="">Select Header</option>
                      {faxHeaderData &&
                        faxHeaderData.map((data, index) => (
                          <option key={index} value={data.header_ID}>
                            {data.header_text}
                          </option>
                        ))}
                    </select>
                    {errors.setHeaderCover && (
                      <small className="text-danger">
                        {errors.setHeaderCover}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Save and Back Buttons */}
            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <div className="btn-group1">
                  {addFaxDeviceLoader ? (
                    <button className="btn btn-primary" disabled>
                      <i className="fas fa-spinner fa-spin" title="Loading"></i>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary waves-light waves-effect"
                      style={{ marginRight: 5 }}
                    >
                      {isEditMode ? "Update" : "Save"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    faxHeaderData: state.setting.faxHeaderData,
    faxHeaderloder: state.setting.faxHeaderloder,
    user: state.auth.user,
    verifiedPhoneFaxNumbers: state.auth.verifiedPhoneFaxNumbers,
    addFaxDeviceLoader: state.faxDevice.addFaxDeviceLoader,
    faxDevice: state.faxDevice.faxDevice,
  };
};

export default connect(mapStateToProps, {
  actionGetFaxHeader,
  actionGetVerifiedPhoneFaxNumbers,
  actionAddFaxDevice,
  actionGetSingleFaxDevice,
  actionUpdateFaxDevice,
})(AddFaxDevice);
