import axios from "axios";
import * as actionTypes from "../action";
import { toast as message } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const actionAddFaxDevice =
  (data, navigate) => async (dispatch, getState) => {
    console.log("actionAddFaxDevice");
    dispatch({ type: actionTypes.ADD_FAX_DEVICE_LOADER, payload: true });
    try {
      let res = await axios.post(
        `${BASE_URL}/fax-devices/add-fax-device`,
        data
      );
      dispatch({ type: actionTypes.ADD_FAX_DEVICE_LOADER, payload: false });
      console.log({ res });

      if (parseInt(res.data.http_status_code) === 201) {
        message.success("Fax Device Added Successfully");
        navigate && navigate("/fax-devices");
      } else {
        // Handle specific error cases
        const errorMessage =
          res.data?.result?.data?.["Error Message"] ||
          res.data.error?.error_message;
        console.log(errorMessage);
        message.error(errorMessage || "An error occurred. Please try again.");
      }
    } catch (error) {
      dispatch({ type: actionTypes.ADD_FAX_DEVICE_LOADER, payload: false });

      // Handle Insufficient Balance and other errors
      const errorResponse = error.response?.data;
      if (
        errorResponse?.result?.data?.["Error Message"] ===
        "Insufficient Balance"
      ) {
        message.error("Insufficient Balance. Please recharge your account.");
      } else {
        console.log(errorResponse?.message || error.message);
        message.error(
          errorResponse?.message || "An error occurred. Please try again."
        );
      }
    }
  };

export const actionGetAllFaxDevices = () => async (dispatch, getState) => {
  console.log("Fetching All Fax Devices");
  dispatch({ type: actionTypes.GET_ALL_FAX_DEVICES_LOADER, payload: true });

  try {
    const res = await axios.get(`${BASE_URL}/fax-devices/get-all-fax-devices`);

    dispatch({ type: actionTypes.GET_ALL_FAX_DEVICES_LOADER, payload: false });

    if (res.data && parseInt(res.data.http_status_code) === 200) {
      const faxDevices = res.data.result?.data?.faxDevices?.data || [];
      dispatch({
        type: actionTypes.GET_ALL_FAX_DEVICES_DATA,
        payload: faxDevices,
      });
    } else {
      console.log(res.data.error?.error_message || "Unknown error occurred");
    }
  } catch (error) {
    console.log(error.response?.data?.message || error.message);
    dispatch({ type: actionTypes.GET_ALL_FAX_DEVICES_LOADER, payload: false });
  }
};

export const actionGetSingleFaxDevice =
  (faxDeviceId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_ALL_FAX_DEVICES_LOADER, payload: true });

    try {
      const res = await axios.get(`${BASE_URL}/fax-devices/${faxDeviceId}`);

      dispatch({
        type: actionTypes.GET_ALL_FAX_DEVICES_LOADER,
        payload: false,
      });

      if (res.data && parseInt(res.data.http_status_code) === 200) {
        const faxDevice = res.data.result?.data?.faxDevice || {};

        // Dispatching the parsed data
        dispatch({
          type: actionTypes.GET_SINGLE_FAX_DEVICE_DATA,
          payload: faxDevice,
        });
      } else {
        console.error(
          res.data.error?.error_message || "Unknown error occurred"
        );
      }
    } catch (error) {
      console.error(error.response?.data?.message || error.message);
      dispatch({
        type: actionTypes.GET_ALL_FAX_DEVICES_LOADER,
        payload: false,
      });
    }
  };

export const actionUpdateFaxDevice =
  (id, data, navigate) => async (dispatch, getState) => {
    console.log("actionUpdateFaxDevice");
    dispatch({ type: actionTypes.ADD_FAX_DEVICE_LOADER, payload: true });
    try {
      let res = await axios.put(
        `${BASE_URL}/fax-devices/update-fax-device/${id}`,
        data
      );
      dispatch({ type: actionTypes.ADD_FAX_DEVICE_LOADER, payload: false });
      console.log({ res });
      if (parseInt(res.data.http_status_code) === 200) {
        message.success("Fax Device Updated Successfully");
        navigate && navigate("/fax-devices");
      } else {
        console.log(res.data.error.error_message);
        message.error(res.data.error.error_message);
      }
    } catch (error) {
      console.log(error.response?.data?.message || "Update failed");
      dispatch({ type: actionTypes.ADD_FAX_DEVICE_LOADER, payload: false });
    }
  };

export const actionDeleteFaxDevice =
  (faxDeviceId) => async (dispatch, getState) => {
    console.log(`Deleting Fax Device with ID: ${faxDeviceId}`);

    dispatch({
      type: actionTypes.DELETE_FAX_DEVICE_LOADER,
      payload: faxDeviceId,
    });

    try {
      const res = await axios.delete(
        `${BASE_URL}/fax-devices/delete-fax-device/${faxDeviceId}`
      );

      dispatch({ type: actionTypes.DELETE_FAX_DEVICE_LOADER, payload: false });

      if (res.data && parseInt(res.data.http_status_code) === 200) {
        const faxDeviceStatus =
          res.data.result?.data?.faxDevice?.status || "Unknown";

        // Check if the fax device was successfully deleted
        if (faxDeviceStatus === "OK") {
          console.log("Fax device deleted successfully.");

          // Display success message using Ant Design's message.success
          message.success("Fax device deleted successfully.");

          dispatch({
            type: actionTypes.DELETE_FAX_DEVICE_SUCCESS,
            payload: faxDeviceId,
          });
        } else {
          console.error("Error deleting fax device: ", faxDeviceStatus);
          // Display error message if deletion fails
          message.error("Failed to delete fax device.");
        }
      } else {
        console.error(
          res.data.error?.error_message || "Unknown error occurred"
        );

        // Display error message for API failure
        message.error(
          res.data.error?.error_message || "Unknown error occurred"
        );
      }
    } catch (error) {
      console.error(error.response?.data?.message || error.message);

      // Display error message in case of an exception
      message.error(error.response?.data?.message || error.message);
      dispatch({ type: actionTypes.DELETE_FAX_DEVICE_LOADER, payload: false });
    }
  };

export const actionGetDeviceCallReport = () => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_DEVICE_CALL_REPORT_LOADER,
    payload: true,
  });

  try {
    const res = await axios.get(`${BASE_URL}/device-call-report`);

    dispatch({
      type: actionTypes.GET_DEVICE_CALL_REPORT_LOADER,
      payload: false,
    });

    if (res.data && parseInt(res.data.http_status_code) === 200) {
      const deviceCallReport = res.data.result?.data?.callReports || [];

      dispatch({
        type: actionTypes.GET_DEVICE_CALL_REPORT_DATA,
        payload: deviceCallReport,
      });
    } else {
      console.error(res.data.error?.error_message || "Unknown error occurred");
    }
  } catch (error) {
    console.error(error.response?.data?.message || error.message);
    dispatch({
      type: actionTypes.GET_DEVICE_CALL_REPORT_LOADER,
      payload: false,
    });
  }
};

export const actionGiftFaxDevice =
  (data, setFormData) => async (dispatch, getState) => {
    console.log("actionGiftFaxDevice");
    dispatch({ type: actionTypes.GIFT_FAX_DEVICE_LOADER, payload: true });

    try {
      const res = await axios.post(
        `${BASE_URL}/fax-devices/gift-fax-device`,
        data
      );

      dispatch({ type: actionTypes.GIFT_FAX_DEVICE_LOADER, payload: false });

      const { http_status_code, result, error } = res.data;

      if (parseInt(http_status_code) === 201 && result?.status === "OK") {
        const giftStatus = result.data.giftStatus;
        message.success(
          `Fax Device successfully gifted to "${giftStatus.data.name}"!`
        );
        setFormData &&
          setFormData({
            name: "",
            faxNumber: "",
            emailAddress: "",
            phoneNumber: "",
          });
      } else if (result?.data?.["Error Message"] === "Insufficient Balance") {
        message.error("Insufficient Balance. Please recharge your account.");
      } else if (error?.error_message) {
        console.log(error.error_message);
        message.error(error.error_message);
      } else {
        message.error("Unexpected error occurred.");
      }
    } catch (error) {
      console.log(error.response?.data?.message || "An error occurred");
      dispatch({ type: actionTypes.GIFT_FAX_DEVICE_LOADER, payload: false });

      // Handle Insufficient Balance or other errors in catch
      const errorResponse = error.response?.data;
      if (
        errorResponse?.result?.data?.["Error Message"] ===
        "Insufficient Balance"
      ) {
        message.error("Insufficient Balance. Please recharge your account.");
      } else {
        message.error(
          errorResponse?.message || "An error occurred. Please try again."
        );
      }
    }
  };
