import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"; // Import useSelector
import { Home, Briefcase, Grid, Box } from "react-feather";

const NavBar = () => {
  // Access the dashboard state
  const dashboard = useSelector((state) => state.auth.dashboard);
  const balance = dashboard?.balance || 0;

  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
          <div className="collapse navbar-collapse" id="topnav-menu-content">
            <ul className="navbar-nav">
              <NavItem icon={<Home />} to="/" text="Dashboard" />
              <NavItem icon={<Briefcase />} to="/inbox" text="Inbox" />
              <DropdownNavItem
                icon={<Grid />}
                text="Fax Numbers"
                items={[
                  { to: "/porting-request", text: "Number Porting" },
                  { to: "/buy-fax-phone-numbers", text: "Buy Numbers" },
                  { to: "/phone-numbers-list", text: "Phone Numbers" },
                  {
                    to: "/verified-phone-fax-list",
                    text: "Verified Phone / Fax Numbers",
                  },
                  { to: "/verified-email-list", text: "Verified Email" },
                ]}
              />
              <DropdownNavItem
                icon={<Grid />}
                text="Fax Documents"
                items={[
                  { to: "/search-document", text: "Search Document" },
                  { to: "/ocr", text: "OCR" },
                ]}
              />
              <DropdownNavItem
                icon={<Box />}
                text="Network"
                items={[
                  { to: "/contacts", text: "Contacts" },
                  { to: "/lists", text: "Lists" },
                  { to: "/referral", text: "Referrals" },
                ]}
              />
              <DropdownNavItem
                icon={<PrinterIcon />}
                text="Fax Devices"
                items={[
                  { to: "/add-fax-device", text: "Add Fax Device" },
                  { to: "/fax-devices", text: "Fax Devices" },
                  {
                    to: "/fax-devices/call-report",
                    text: "Device Call Report",
                  },
                  { to: "/gift-fax-machine", text: "Gift a Fax Machine" },
                ]}
              />
            </ul>
          </div>
        </nav>
      </div>
      {balance <= 0 && (
        <div
          style={{
            backgroundColor: "#ffdddd",
            color: "#d9534f",
            padding: "10px",
            textAlign: "center",
            fontWeight: "bold",
            margin: "auto",
          }}
        >
          Insufficient Balance (${balance}). Please{" "}
          <a
            href="/billing/add-fund"
            style={{
              color: "#007bff",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            add funds
          </a>{" "}
          to your account.
        </div>
      )}
    </div>
  );
};

const NavItem = ({ icon, to, text }) => (
  <li className="nav-item dropdown">
    <Link className="nav-link dropdown-toggle arrow-none" to={to} role="button">
      {icon}
      <span>{text}</span>
    </Link>
  </li>
);

const DropdownNavItem = ({ icon, text, items }) => (
  <li className="nav-item dropdown">
    <Link className="nav-link dropdown-toggle arrow-none" to="/" role="button">
      {icon}
      <span>{text}</span>
      <div className="arrow-down" />
    </Link>
    <div className="dropdown-menu" aria-labelledby="topnav-pages">
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.to}
          className="dropdown-item"
          data-key={`t-${item.text.toLowerCase()}`}
        >
          {item.text}
        </Link>
      ))}
    </div>
  </li>
);

const PrinterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-printer"
  >
    <polyline points="6 9 6 2 18 2 18 9" />
    <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
    <rect x={6} y={14} width={12} height={8} />
  </svg>
);

export default NavBar;
